jQuery(window).on("load resize", function (e) {
  /* Open menu */
  jQuery(".menu-toggle").on("click", function (evt) {
    jQuery("body").toggleClass("menu-open");
    evt.stopImmediatePropagation();
  });

  /* Close menu on big screen */
  if (jQuery(window).width() > 1359) {
    jQuery("body").removeClass("menu-open");
  }

  if (jQuery(".menu-list__item-toggle")) {
    jQuery(".menu-list__item-toggle").on("click", function (evt) {
      evt.stopImmediatePropagation();
      // slide toggle the submenu
      jQuery(this)
        .closest(".menu-list__item")
        .find(".menu-list__sub-menu")
        .slideToggle();
    });
  }
});
