export default {
    init() {
        // console.log("Retail template init");
    },
    finalize() {
        // console.log("Retail template finalize");
        // Check and initialize the form table
        var retailerForm = jQuery("#retailer-form");
        var updateAddressField = retailerForm.find("input.js-enable-address-checkbox");

        if (updateAddressField && updateAddressField.length) {
            var addressFieldBlock = retailerForm.find(".js-address-fields");

            // When the checkbox is checked, show the address fields
            if (addressFieldBlock && addressFieldBlock.length) {
                jQuery(updateAddressField).on("change", function () {
                    if (jQuery(this).is(":checked")) {
                        addressFieldBlock.removeClass("hidden");
                        // Slide down the address fields
                        jQuery(addressFieldBlock).slideDown();
                        // Remove the disabled attribute from the addressFieldBlock
                        jQuery(addressFieldBlock).removeAttr("disabled");
                    } else {
                        addressFieldBlock.addClass("hidden");
                        // Slide up the address fields
                        jQuery(addressFieldBlock).slideUp();
                        // Add the disabled attribute to the addressFieldBlock
                        jQuery(addressFieldBlock).attr("disabled", true);
                    }
                });
            }
        }

        jQuery(document).ready(function ($) {
            var retailerForm = jQuery("#retailer-form");

            /* Get reCaptcha script */
            jQuery
                .getScript(
                    "https://www.google.com/recaptcha/api.js?render=6Le8hKYUAAAAAMD06s7JPgHGQYxuY2YLx0Eo7Bl4"
                )
                .done(function () {
                    // console.log("DONE - recaptcha external js loaded");
                    /* Continue reCaptcha on form submit */
                    $(retailerForm).on("submit", function (e) {
                        // console.log("Submit form. Call reCaptcha");
                        e.preventDefault();

                        /* Disable submit button */
                        jQuery(retailerForm)
                            .find("input.retailer-btn")
                            .attr("disabled", true);

                        /* Loading animation */
                        jQuery(retailerForm)
                            .find("span.loader")
                            .addClass("loading-cart-sh");

                        grecaptcha
                            .execute("6Le8hKYUAAAAAMD06s7JPgHGQYxuY2YLx0Eo7Bl4", {
                                action: "retail_form",
                            })
                            /* When it's ready */
                            .then(function (token) {
                                /* Get score from Google reCaptcha */
                                recaptcha_get_score(token);
                            });
                    });
                })
                /* Failed getScript */
                .fail(function () {
                    // console.log("FAIL - recaptcha external js load");
                });
        });
    },
};

/* Get score from Google reCaptcha */
function recaptcha_get_score(token) {
    var data = {
        action: "recaptcha_ajax",
        /* Send reCaptcha token to AJAX */
        recaptcha_token: token,
    };

    jQuery.ajax({
        type: "post",
        url: adminAjaxURL,
        data: data,
        success: function (response) {
            // console.log("SUCCESS - recaptcha_ajax");

            // console.log("Data:");
            // console.log(data);

            // console.log("Response:");
            var responseJSON = jQuery.parseJSON(response);
            // console.log(responseJSON);

            var retailerForm = jQuery("#retailer-form");

            /* Check the score from the reCaptcha response */
            if (responseJSON.score >= 0.3) {
                // console.log("Score is >=0.3");
                /* Send retailer E-mail with adata from the form */
                send_retailer_email();
            } else {
                // console.log("Score is NOT >=0.4");
                /* Multisite language */
                if (jQuery("body.site-1").length) {
                    /* Swedish */
                    retailerForm
                        .find(".form-footer")
                        .append(
                            "<p class='tac error'>Valideringen lyckades inte. Vänligen försök igen senare.</p>"
                        );
                } else {
                    /* English */
                    retailerForm
                        .find(".form-footer")
                        .append(
                            "<p class='tac error'>Validation was unsuccessful. Please try again later.</p>"
                        );
                }
            }
        },
        /* ----------------------------------------------------- */
        error: function (response) {
            // console.log("ERROR - recaptcha_ajax");
            // console.log("Data:");
            // console.log(data);
            // console.log("Response:");
            // var responseJSON = jQuery.parseJSON(response);
            // console.log(responseJSON);
        },
        /* ----------------------------------------------------- */
    });
}

/* Send retailer E-mail with adata from the form */
function send_retailer_email() {
    var retailerForm = jQuery("#retailer-form");

    /* ------------------------------------------- */
    /* Inputs from retailer form */
    var orgNo = jQuery(retailerForm).find("input[name='org-nr']").val();
    var store = jQuery(retailerForm).find("input[name='store']").val();
    var company = jQuery(retailerForm).find("input[name='company']").val();
    var hasCustomAddress = jQuery(retailerForm).find("input.js-enable-address-checkbox").is(":checked");
    var deliveryAddress = jQuery(retailerForm)
        .find("input[name='delivery-address']")
        .val();
    var zipCode = jQuery(retailerForm).find("input[name='zip-code']").val();
    var billingAddress = jQuery(retailerForm)
        .find("input[name='billing-address']")
        .val();
    var contactPerson = jQuery(retailerForm)
        .find("input[name='contact-person']")
        .val();
    var phone = jQuery(retailerForm).find("input[name='phone']").val();
    var email = jQuery(retailerForm).find("input[name='email']").val();

    var deliverySelection = jQuery(retailerForm).find("#delivery-option option:selected").val();
    var requestedDeliveryDate = jQuery(retailerForm).find("input[name='delivery-date']").val();
    var orderNotes = jQuery(retailerForm).find("input[name='order-notes']").val();

    var couponCode = jQuery(retailerForm)
        .find("input[name='campaign-code']")
        .val();
    /* ------------------------------------------- */

    /* ------------------------------------------- */
    /* Create the form table */
    var retailerTable = jQuery(".retailer.table-wrap");
    var table_array = new Array();

    /* Create table, thead, tr */
    table_array.push("<table><thead><tr>");
    jQuery(retailerTable)
        .find("table thead tr th")
        .each(function () {
            /* Create th in thead */
            table_array.push("<th>" + jQuery(this).text() + "</th>");
        });
    /* End tr and thead */
    table_array.push("</tr></thead>");

    table_array.push("<tbody>");
    jQuery(retailerTable)
        .find("table tbody tr")
        .each(function () {
            /* Create tr */
            table_array.push("<tr>");

            /* Create tds */
            /* Number */
            table_array.push(
                "<td class='article-number'>" +
                jQuery(this).find("td.article-number").text() +
                "</td>"
            );
            /* Name */
            table_array.push(
                "<td class='article-name'>" +
                jQuery(this).find("td.article-name").text() +
                "</td>"
            );
            /* Article amount */
            table_array.push(
                "<td class='article-amount'>" +
                jQuery(this).find("td.article-amount").text() +
                "</td>"
            );
            /* Quantity data value */
            table_array.push(
                "<td class='article-quantity'>" +
                /* Sanitize. Strip everything but numbers. */
                jQuery(this)
                    .find("td.article-quantity input.amount-input")
                    .attr("data-value")
                    .replace(/\D/g, "") +
                " st</td>"
            );

            /* End tr */
            table_array.push("</tr>");
        });
    /* End tbody, table */
    table_array.push("</tbody></table>");

    // console.log(table_array);
    /* ------------------------------------------- */

    var deliverySelection = jQuery(retailerForm).find("#delivery-option option:selected").val();
    var requestedDeliveryDate = jQuery(retailerForm).find("input[name='delivery-date']").val();
    var orderNotes = jQuery(retailerForm).find("input[name='order-notes']").val();
    var nameCustomer = jQuery(retailerForm).find("input[name='name_customer']").val();
    var emailCustomer = jQuery(retailerForm).find("input[name='email_customer']").val();
    //billing_address: billingAddress,
    var data = {
        action: "retailer_send_email_ajax",
        retailer_table: table_array,
        has_custom_address: hasCustomAddress ? 1 : 0,
        org_no: orgNo,
        store: store,
        company: company,
        name_customer: nameCustomer,
        email_customer: emailCustomer,
        delivery_address: deliveryAddress,
        zip_code: zipCode,
        contact_person: contactPerson,
        phone: phone,
        email: email,
        delivery_selection: deliverySelection,
        requested_delivery_date: requestedDeliveryDate,
        order_notes: orderNotes,
        coupon_code: couponCode,
    };

    jQuery.ajax({
        type: "post",
        url: adminAjaxURL,
        data: data,
        success: function (response) {
            // console.log("SUCCESS - retailer_send_email_ajax");

            // console.log("Data:");
            // console.log(data);

            // console.log("Response:");
            // console.log(response);

            /* Remove loading animation */
            jQuery(retailerForm).find("span.loader").removeClass("loading-cart-sh");

            /* --------------- */
            if (response == 1) {
                // console.log("E-mail sent");
                /* Append thank you message */
                /* Multisite language */
                if (jQuery("body.site-1").length) {
                    /* Swedish */
                    retailerForm
                        .find(".form-footer")
                        .append("<p class='tac thank-you'>Tack för din beställning!</p>");
                } else {
                    /* English */
                    retailerForm
                        .find(".form-footer")
                        .append("<p class='tac thank-you'>Thank you for your order!</p>");
                }
                /* --------------- */
            } else {
                // console.log("E-mail NOT sent");
            }
        },
        /* ----------------------------------------------------- */
        error: function (response) {
            // console.log("ERROR - retailer_send_email_ajax");
            // console.log("Data:");
            // console.log(data);
            // console.log("Response:");
            // var responseJSON = jQuery.parseJSON(response);
            // console.log(responseJSON);
        },
        /* ----------------------------------------------------- */
    });
}
